import React, { useState } from "react";
const SecurityFaq = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <div className="faq-list">
        <h2 className="h2 mt-3">Security related information</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
  {
    question: "What do I do if I forget my password?",
    answer:
      "To retrieve your password, click on 'Login' and then click on 'Forgot Password'. Now enter the email id registered with KalpTaru.com and press 'Submit'. Your password would be sent to your registered email address.",
  },
  {
    question: "Is my credit card data safe?",
    answer:
      "KalpTaru.com does not collect your credit card/debit card or Bank Account details. After Clicking on 'Pay now' button, you will be directed to CCAvenue's secure gateway for payment. CCAvenue is India's leading payment gateway. After completing the payment process, you will be redirected to KalpTaru.com.",
  },
];

export default SecurityFaq;
