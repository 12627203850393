import React, { useState } from "react";
const Basicinfo = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <div className="faq-list">
        <h2 className="h2 mt-3">Basic Information About The Website & Service</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
    {
        question: "What are the different membership plans available?",
        answer:
          "There are a number of plans based on the number of books that a person would like to read in a month and also the duration for which the customer would like to subscribe. For details please refer the 'Membership Plan' page on the website.",
      },
      {
        question: "How do I make the payment?",
        answer:
          "You can make the payment through any one of the following methods: Credit card/Debit Card Net banking Cash / Gift Voucher Cheque / Draft. If you want to make the payment through cash/cheque/draft/gift voucher, just select the appropriate option. Our representative would come to you to collect the payment. The cheque/draft should be made in the name of 'Manya Knowledge Services'.",
      },
      {
        question: "What is My Booklist?",
        answer:
          "My Booklist represents the list of books that you want to read. The order in which you would like to read the books can be set by giving priority numbers to the books.",
      },
      {
        question: "How do I manage My Booklist?",
        answer:
          "You can manage your Booklist by changing the priority of the books available in your Booklist. You can also remove a book from your Booklist. To add a book to your Booklist, browse/search a book and click on 'Add to Booklist' button. To remove a book, click on the delete icon on the same row as the book on the Booklist. To rearrange books in the Booklist you have four options Move to First - Move this book to the top of the Booklist. Move one Up - Move this book one level up in priority. Move one Down - Move this book one level down in priority. Move to Last - Move this book to the end of the Booklist. Always click Update priority after rearranging books.",
      },
      {
        question: "What is My Account?",
        answer:
          "My Account is the section from where you can view and manage details regarding your account. You can edit your profile, change password, see billing details, renew and changer your membership.",
      },
      {
        question: "What is the minimum number of books I need to maintain in My Booklist?",
        answer:
          "In order to have a high probability of a delivery, we need you to maintain at least 5 books in the Booklist, at any point of time.",
      },
      {
        question: "How do I get books delivered after opening my account?",
        answer:
          "After your have created your account, kindly add atleast 5 books in your booklist. Once this is done, you have to click on the 'Deliver' button present at the bottom of your Bookslist. The top available books from your booklist would be issued to you and would be shown in 'Books in delivery' section.",
      },
      {
        question: "What if the books are not delivered within 1-3 working days?",
        answer:
          "Please give us a call at 033-65365122 , 09830448800 and we will take the case on a priority basis.",
      },
      {
        question: "After I finish reading, how do I get my next set of books?",
        answer:
          "After you have finished reading, please press 'Pickup and deliver' (if you want the next book to be delivered) or just 'Pickup' (if you do not want the next books now). We would pickup the books that you have with you and deliver the next set of books from your booklist (if you have selected for pickup & delivery) within 1-3 working days.",
      },
      {
        question: "Can I upgrade/downgrade my membership?",
        answer:
          "You can upgrade your membership in between, but you cannot downgrade a plan while it is running. Downgrade is only possible after expiry of the current membership plan.",
      },
      {
        question: "How long can I keep the books with me?",
        answer:
          "As long as you are making your monthly rental payments, you can keep the books with you till you please. For corporate members, there are time limits specified by your company. Kindly contact your company's HR to find out the same. You can also contact us to know the details.",
      },
      {
        question: "What if I damage/lose the book?",
        answer:
          "If you lose/damage the book, you need to pay the MRP of the book to us, to continue the service.",
      },
      {
        question: "Can I cancel my membership during my membership period?",
        answer:
          "Yes, you can cancel your membership at anytime during your membership period. This can be done by dropping an email to customercare@doorstepsooks.com. Our customer care executive will call you to confirm your request and also note the reason for closure. You will have to return any books that you have with you within 5 working days failing which an amount equal to the MRP of the books would be deducted from your security deposit. Any amount left after deducting the dues from your security deposit would be given to you via a cheque. There would however be no refund of the membership fees or the registration fees.",
      },
      {
        question: "How do I surrender/close my membership?",
        answer:
          "In order to surrender/close your membership, you will have to send us a surrender request via email at customercare@doorstepbooks.com stating the reason of closure and the details of your bank account where you would want the security deposit. This request should be made before the expiry date of your current membership. You will also have to return any books pending with you. Once we receive the request, our customer care executive will call you for confirmation and check if the books with you have been returned. If books are not returned in time security will not be refunded in full. Rental charges for the extra time will be deducted and refunded. If you donot return books, security deposit will be defaced or adjusted with the security and there will be no refund.",
      },
      {
        question: "If I cannot issue the books of the month in a month can I take them next month?",
        answer:
          "No, books cannot be carried forward. If you cannot issue within your month period you will lose the count. And previous books with you will be carried forward.",
      },
      {
          question: "If I register but don't rent any books during the period, can I use it later?",
          answer: "Membership term cannot be changed. If you don't rent, your membership charges will be forfeited. You can renew and start again. If you don't want to continue, mail customer care and they will refund the security deposit with us.",
        },
];

export default Basicinfo;
