import React from "react";

const ContactFormCard = () => {
  return (
    <div className="container-fluid mt-3" id="contactus">
      <h2 class="mt3 h2">Contact Us</h2>
      <div class="contact-form">
        <p>(*) Asterisk denotes mandatory fields</p>
        <div class="form-columns">
          <div class="form-column">
            <form>
              <div class="form-row">
                <div class="form-group">
                  {/* <label for="firstName">First name*</label> */}
                  <input placeholder="First name*" type="text" id="firstName" required />
                </div>
                <div class="form-group">
                  {/* <label for="lastName">Last name*</label> */}
                  <input placeholder="Last name*" type="text" id="lastName" required />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group">
                  {/* <label for="workEmail">Work email*</label> */}
                  <input placeholder="Email*" type="email" id="workEmail" required />
                </div>
                <div class="form-group">
                  {/* <label for="phoneNumber">Phone number (optional)</label> */}
                  <input placeholder="Phone number (optional)" type="tel" id="phoneNumber" />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  required
                  id="flexCheckChecked"
                />
                <label class="form-check-label" for="flexCheckChecked">
                  I agree to the <a href="#">privacy policy</a>
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="subscribe"
                />
                <label class="form-check-label" for="subscribe">
                  Subscribe me to KalpTaru's latest thought leadership, blogs
                  and updates.
                </label>
              </div>

              <div class="form-buttons">
                <button className="btn" type="submit">Submit</button>
               
              </div>
            </form>
          </div>
          <div class="form-column">
            <div class="form-group">
              {/* <label for="message"></label> */}
              <textarea placeholder="How can we help you?" id="message"></textarea>
            </div>
            <p>
              You can also email us directly at{" "}
              <a href="mailto:info@kalptaru.com">info@kalptaru.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormCard;
