import React from 'react';
import { useLocation } from "react-router-dom";
import '../assets/styles/custom.css';
import Header from '../components/Header';
import MyCarousel from '../components/MyCarousel';
import Categories from '../components/Categories';
import TopAuthors from '../components/TopAuthors';
import Bestselling from '../components/Bestselling';
import AwardWinningBooks from '../components/AwardWinningBooks';
import Genre from '../components/Genre';
import DownloadAppSection from '../components/DownloadAppSection';
import ContactFormCard from '../components/ContactFormCard';
import Footer from '../components/Footer';

const Home = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/";
  return (
    <>
        <Header isAboutPage={isAboutPage} />
        <MyCarousel/>
        <Categories/>
        <TopAuthors/>
        <Bestselling/>
        <AwardWinningBooks/>
        <Genre/>
        <DownloadAppSection/>
        <ContactFormCard/>
        <Footer/>
    </>
  )
}

export default Home