import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <Routes> {/* Use Routes instead of Switch */}
        <Route exact path="/" element={<Home />} /> {/* Use element prop instead of component */}
        <Route path="about" element={<About />} /> {/* Use element prop instead of component */}
        <Route path="/contact" element={<Contact />} /> {/* Use element prop instead of component */}
        <Route path="/faq" element={<FAQ />} /> {/* Use element prop instead of component */}

        <Route path="*" element={<NotFound />} /> {/* Use path="*" for 404 routes */}
      </Routes>
    </Router>
  );
}

export default App;
