import React from "react";

const AboutSection = () => {
  return (
    <>
      <div className="mt-3 container-fluid">
        <main>
          <section class="hero">
            <div class="hero-content">
              <h1 className="h1">About KalpTaru</h1>
              <p>
                KalpTaru is dedicated to fostering a love for reading among
                youth, drawing inspiration from ancient Indian traditions of
                learning. Through curated book selections and innovative
                programs, we aim to cultivate critical thinking and creativity,
                empowering individuals to navigate the complexities of the
                modern world. Our vision includes expanding our reach nationwide
                and globally, leveraging technology and partnerships to drive
                positive social change. As we celebrate India's 100 years of
                independence, we aspire to contribute to a society where access
                to quality education and literature transforms lives, leaving a
                legacy of empowerment and enlightenment.
              </p>
            </div>
            <div class="hero-image">
              <img src="imagespu/girl-8602014_1280.png" alt="Hero Image" />
            </div>
          </section>

          <section class="history mission">
            <div style={{ textAlign: "left" }} class="history-image">
              <img src="imagespu/mission.png" alt="History Image" />
            </div>
            <div class="history-content">
              <h2 className="h2">Mission</h2>

              <p>
                At KalpTaru, our mission is to ignite a passion for reading
                among young minds and empower them with the transformative
                knowledge found within books. Inspired by the ancient Indian
                tradition of learning from gurus, we strive to create a
                nurturing environment where children and young adults can
                discover the joy of reading and embark on a journey of lifelong
                learning. Through our innovative programs and curated selection
                of books, we aim to cultivate critical thinking, creativity, and
                empathy, fostering the growth of well-rounded individuals who
                are equipped to navigate the complexities of the modern world.
                At KalpTaru, we believe that every book has the power to shape
                minds, inspire dreams, and create positive change, and we are
                committed to making the world a brighter place, one reader at a
                time.
              </p>
            </div>
          </section>

          <section class="vision">
            <div class="vision-content">
              <h2 className="h2">OUR VISION</h2>
              <p>
                In the next five years, KalpTaru envisions becoming a
                cornerstone of the literary landscape, reaching every corner of
                India and beyond, nurturing a generation of avid readers and
                lifelong learners. By fostering a culture of reading and
                intellectual curiosity, we aim to empower millions of young
                minds, equipping them with the knowledge, skills, and values
                necessary to thrive in the 21st century.
              </p>
              <p>
                Looking ahead to the next decade, KalpTaru strives to be
                recognized globally as a trailblazer in educational innovation,
                with a diverse array of programs and initiatives that transcend
                borders and cultures. By leveraging cutting-edge technology and
                collaborative partnerships, we seek to revolutionize the way
                people engage with books and ideas, driving positive social
                change and fostering a more enlightened and interconnected
                world.
              </p>
              <p>
                As India celebrates its 100 years of independence in 2047,
                KalpTaru envisions a society where every individual has access
                to quality education and the transformative power of literature.
                By championing literacy and lifelong learning, we aim to
                contribute to the realization of India's full potential as a
                knowledge economy and a beacon of wisdom and enlightenment for
                future generations. Through our unwavering commitment to
                promoting a love for reading and nurturing the minds of
                tomorrow's leaders, KalpTaru aspires to leave a lasting legacy
                of empowerment, enlightenment, and excellence.
              </p>
            </div>
          </section>

          <section class="hero">
            <div class="hero-content">
              <h2 className="h2">Executive Summary</h2>
              <p>
                KalpTaru is a passionate endeavor driven by a group of
                enthusiastic Indians determined to revive India's culture of
                reading and restore its historical prominence as the Vishwaguru,
                or the world's preeminent source of knowledge. Our mission is to
                reignite the love for reading across the nation and empower
                individuals with the wisdom and enlightenment found within
                books.
              </p>
              <p>
                With a deep-rooted belief in the transformative power of
                literature, KalpTaru aims to cultivate a society where every
                individual values the pursuit of knowledge and intellectual
                growth. Through innovative programs, partnerships, and digital
                platforms, we aspire to create a nationwide movement that
                celebrates reading as a cornerstone of personal development and
                societal progress.
              </p>
              <p>
                Join us on this transformative journey as we work towards
                realizing our vision of a nation where reading is revered,
                knowledge is cherished, and India shines once again as the
                Vishwaguru of the world. Together, let us embark on a quest to
                unlock the limitless potential of every individual and
                collectively contribute to India's renaissance as a beacon of
                wisdom and enlightenment for humanity.
              </p>
            </div>
            <div class="hero-image">
              <img src="imagespu/reading-5173530_1280.jpg" alt="Hero Image" />
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default AboutSection;
