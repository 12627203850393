import React from "react";

const TopAuthors = () => {
  return (
    <div className="container-fluid">
      <h2 className="h2 mt-3">Top Author's</h2>
      <div className="card-container">
        <div className="author-card-container">
          <div className="author-card-wrapper">
           
          <a href=""> <div className="author-card">
              <div className="author-photo-container">
                <img
                  src="imagespu/authors/anitanair.jpeg"
                  alt="Avatar"
                  className="author-photo"
                />
              </div>
              <span href="" className="author-name">
                Anita Nair
              </span>
            </div>
            </a>
            <a href="">
            <div className="author-card">
              <div className="author-photo-container">
                <img
                  src="imagespu/authors/R_C_Majumdar.jpg"
                  alt="Avatar"
                  className="author-photo"
                />
              </div>
              <span href="" className="author-name">
                R C Majumdar
              </span>
            </div>
            </a>
            <a href="">
              <div className="author-card">
                <div className="author-photo-container">
                  <img
                    src="imagespu/authors/sadhguru.jpeg"
                    alt="Avatar"
                    className="author-photo"
                  />
                </div>
                <span href="" className="author-name">
                  Sadhguru
                </span>
              </div>
            </a>
            <a href="">
              <div className="author-card">
                <div className="author-photo-container">
                  <img
                    src="imagespu/authors/chetanbhagat.jpeg"
                    alt="Avatar"
                    className="author-photo"
                  />
                </div>
                <span href="" className="author-name">
                  Chetan Bhagat
                </span>
              </div>
            </a>
            <a href="">
              <div className="author-card">
                <div className="author-photo-container">
                  <img
                    src="imagespu/authors/Swami_Vivekananda.jpg"
                    alt="Avatar"
                    className="author-photo"
                  />
                </div>
                <span href="" className="author-name">
                  Swami Vivekananda
                </span>
              </div>
            </a>
            <a href="">
              <div className="author-card">
                <div className="author-photo-container">
                  <img
                    src="imagespu/authors/JhumpaLahiri.jpg"
                    alt="Avatar"
                    className="author-photo"
                  />
                </div>
                <span href="" className="author-name">
                  Jhumpa Lahiri
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAuthors;
