import React, { useState } from "react";
const PaymentFaq = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <div className="faq-list">
        <h2 className="h2 mt-3">Billing & Payment Related Information</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
    {
        question: "How do I renew my Account?",
        answer: "You can renew your account by going to My Account > Renew/change membership > Renew Account. You can renew your account only if you are within the expiry date of your membership.",
      },
      {
        question: "How do I change my membership?",
        answer: "You can change your subscription by going to My Account > Renew/Change Membership > Change Membership. Select the membership that you would like to take and make the payment through any one of the payment options available.",
      },
      {
        question: "How are the two options 'renew account' and 'change membership' different?",
        answer: "Renew Account works only if you are within the expiry date of your current membership plan and would like to renew it. A Change Membership option can be availed whenever you want, and the changed membership would be effective from the start of the next month as per your billing cycle.",
      },
      {
        question: "Are there any charges for late payment of subscription fees?",
        answer: "A late fine of Rs.30 is charged if the subscription fees are not paid within 14 days from the date of generation of bill. We would also not issue any books to you until the payment is cleared.",
      },
      {
        question: "What is Automatic Billing?",
        answer: "Automatic billing automatically renews your account if you have not requested for pickup of the books with you before the expiry date. You can see the bill in My Account > Billing.",
      },
      {
        question: "What should I do if want to restart my service after a gap of some days?",
        answer: "To start your service, go to My Account > Renew/Change Membership > Change Membership. Choose your plan, make the payment, add books to your Booklist, and start enjoying your books.",
      },
      {
        question: "How do I see my billing details?",
        answer: "You can see your billing details by logging into the website and going to My Account > Billing Details. There you can see a list of all the bills generated for your account. Click on the bill number to see the details.",
      },
];

export default PaymentFaq;
