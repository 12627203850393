import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const MyCarousel = () => {
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-next-arrow" onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="container-fluid mt-2">
      <style>
        {`
          .custom-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 40px; /* Adjust as needed */
            height: 40px; /* Adjust as needed */
            background-color: rgba(0, 0, 0, 0.5); /* Example background color */
            color: white; /* Example color */
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1; /* Ensure buttons are displayed above other elements */
          }

          .custom-prev-arrow {
            left: 20px; /* Adjust the left position */
          }

          .custom-next-arrow {
            right: 20px; /* Adjust the right position */
          }

          .slick-slide {
  display: flex !important;
  justify-content: center;
}

.slick-slide img {
  max-width: 100%;
  height: auto;
}

          @media (max-width: 768px) {
            /* Hide arrow buttons on mobile devices */
            .custom-arrow {
              display: none;
            }
          }
        `}
      </style>
      <Slider {...settings}>
        <div>
          <img
            src="/imagespu/car1.jpg"
            alt="Slide 1"
            className="carousel-image"
          />
        </div>
        <div>
          <img
            src="/imagespu/car2.jpg"
            alt="Slide 2"
            className="carousel-image"
          />
        </div>
        <div>
          <img
            src="/imagespu/car3.jpg"
            alt="Slide 3"
            className="carousel-image"
          />
        </div>
      </Slider>
    </div>
  );
};

export default MyCarousel;
