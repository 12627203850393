import React from "react";
import Logo from "../assets/images/rectangular-logo.png";
import SocialsContainer from "./SocialsContainer";

const Footer = () => {
  return (
    <>
      <div className="container-fluid">
        <hr />

        <div
          className="footer-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img style={{width:'250px'}} className="footer-img" src={Logo} />
          </div>

          <div>
            <ul style={{margin:'auto'}} className="footer-menu">
              <li>
                <a href="">Sitemap</a>
              </li>
              <li>
                <a href="">Privacy Notice</a>
              </li>
              <li>
                <a href="">Cookie Policy</a>
              </li>
              <li>
                <a href="">Terms of Use</a>
              </li>
              <li>
                <a href="">&copy;2024 KalpTaru</a>
              </li>
            </ul>
          </div>

          <div>
            <SocialsContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
