import React from "react";

const Bestselling = () => {
  return (
    <div className="container-fluid">
      <div class="bestbooks-header">
        <h2 class="mt3 h2">Top Reads</h2>
        <a href="" class="bestbooks-show-more fw-bold">
          Show More
        </a>
      </div>

      <div class="bookslist">
        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/thepsychologyofmoney.jpg"
            />
            <p className="books-text-body">The Psychology of Money</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/theintelligentinvestor.jpg"
            />
            <p className="books-text-body">The Intelligent Investor</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/richdadpoordad.jpg"
            />
            <p className="books-text-body">Rich Dad Poor Dad</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/Journeytothecenteroftheearcth.jpg"
            />
            <p className="books-text-body">Journey to the center of earth</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/Kalki.jpg"
            />
            <p className="books-text-body">Kalki</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

        <div className="books-card">
          <div className="books-card-details">
            <img
              className="booksimage"
              src="imagespu/books/Kohinoor.jpg"
            />
            <p className="books-text-body">Kohinoor</p>
          </div>
          <button className="books-card-button" href="#link">
            Add to Cart
          </button>
        </div>

    
      </div>
    </div>
  );
};

export default Bestselling;
