import React from "react";

const Categories = () => {
  return (
    <>
      <div className="container-fluid">
        <h2 className="h2 mt-3">Categories</h2>
        <div class="card-container">
          <a href="bestread">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/top.png" />
                <p className="genre">Best Read</p>
              </div>
            </div>
          </a>
          <a href="newrelease">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/newbook.png" />
                <p className="genre">New Release</p>
              </div>
            </div>
          </a>

          <a href="expertpick">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/expert.png" />
                <p className="genre">Expert Pick</p>
              </div>
            </div>
          </a>

          <a href="language">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/translate.png" />
                <p className="genre">Language</p>
              </div>
            </div>
          </a>

          <a href="awardwinners">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/award.png" />
                <p className="genre">Award Winners</p>
              </div>
            </div>
          </a>

          <a href="childrens">
            <div className="cardContainer">
              <div className="card">
                <img src="imagespu/icon/baby-book.png" />
                <p className="genre">Children's Corner</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Categories;
