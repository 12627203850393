import React, { useState } from "react";
const Aboutfaq = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <h1 className="faq-heading">Frequently Asked Questions</h1>
      <div className="faq-list">
        <h2 className="h2 mt-3">About KalpTaruonline.com</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
  {
    question: "Are these e-Books?",
    answer:
      "No, these are not e-Books. We stock real books with us. Just like what you see in bookstores and libraries.",
  },
];

export default Aboutfaq;
