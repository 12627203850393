import React, { useState } from "react";
const DeliveryFaq = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <div className="faq-list">
        <h2 className="h2 mt-3">Delivery related Information</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
    {
        question: "Do I need to pay for delivery and pickup of books?",
        answer: "No, Pickup and delivery is absolutely free.",
      },
      {
        question: "When would the books be delivered?",
        answer: "We aim to deliver the books within 1-3 working days from the moment you request a delivery. This might however get delayed, if there is any unfavourable situation like bandh, storm, heavy rain etc.",
      },
      {
        question: "What if I am not available at home when the delivery happens?",
        answer: "If you are not available at home and miss a delivery, then kindly call our customer care or email us to request for a re-delivery. Kindly note that free delivery/pickup would only be done twice. If you miss it again, then you would be charged Rs. 25 for the next delivery/pickup.",
      },
      {
        question: "How many books would be delivered in a single delivery?",
        answer: "The maximum number of books delivered is as per your plan. For most of the plan options, it is two.",
      },
      {
        question: "How many deliveries would be made in a month?",
        answer: "The maximum number of deliveries is as per your plan. However you cannot have more than one delivery in a single day.",
      },
      {
        question: "Do I need to return both the books at the same time or can I return one book and retain the other?",
        answer: "You have to return both the books in order to get the next set of books.",
      },
      {
        question: "What if the books sent to me are not in good condition?",
        answer: "If you find that the books sent to you are not in good condition, then we request you to not accept the delivery, and register the issue with us by calling us on 033-65365122 , 09830448800 or by mailing us at customercare@doorstepbooks.com. We will address the issue as soon as possible, and meanwhile, we would send you the next set of books.",
      },
      {
        question: "What are the different options of requesting a pickup/delivery?",
        answer: "Pickup / delivery request can be made through any of the following options: (a).Through your account by going to 'My Booklist', (b).Via SMS, (c).Calling our customer care at 9830448800 or 033-65365122.",
      },
      {
        question: "Can I return the books to your office directly?",
        answer: "No, you have to go through any of the above listed methods.",
      },
      {
        question: "What do I do if I get a wrong book?",
        answer: "Please call us to register the issue. We would send you the books as per your Booklist and take back the book wrongly delivered to you.",
      },
      {
        question: "Will I get the book which is on the top of my priority list in the Booklist?",
        answer: "We try to give you the books as per your priority, but if the same is in circulation then you get the next available book.",
      },
      {
        question: "If I have registered as a member of a particular city, for example Mumbai, then can I get deliveries in any other cities?",
        answer: "If you are registered as a member of Mumbai, then deliveries would be done only in Mumbai. You cannot get deliveries in any other city. You can however change your address within Mumbai whenever you want.",
      },
];

export default DeliveryFaq;
