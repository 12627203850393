import React from "react";
import Header from "../components/Header";
import Aboutfaq from "../components/faq/Aboutfaq";
import Basicinfo from "../components/faq/Basicinfo";
import PaymentFaq from "../components/faq/PaymentFaq";
import DeliveryFaq from "../components/faq/DeliveryFaq";
import SpecialFAQ from "../components/faq/SpecialFAQ";
import SecurityFaq from "../components/faq/SecurityFaq";
import Footer from "../components/Footer";

const FAQ = () => {
  return (
    <>
      <Header />
      <div className="container-fluid faq-container">
      <Aboutfaq/>
      <Basicinfo/>
      <PaymentFaq/>
      <DeliveryFaq/>
      <SpecialFAQ/>
      <SecurityFaq/>
      </div>
      <Footer/>
     
    </>
  );
};

export default FAQ;
