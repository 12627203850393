import React from "react";

const Genre = () => {
  return (
    <>
      <div className="container-fluid">
        <h2 className="h2 mt-3">Genre</h2>
        <div class="card-container">
          <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/poetry.png" />
              <h2>Literature</h2>
            </div>
          </a>

          <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/anonymous.png" />
              <h2>Mystery</h2>
            </div>
          </a>

           <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/thriller.png" />
              <h2>Thriller</h2>
            </div>
          </a>

          <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/romance.png" />
              <h2>Romance</h2>
            </div>
          </a>

          <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/team.png" />
              <h2>Business</h2>
            </div>
          </a>

          <a href="bestread">
            <div className="genre-card">
              <img className="genreimg" src="imagespu/genre/flask.png" />
              <h2>Science</h2>
            </div>
          </a>

        </div>
      </div>
    </>
  );
};

export default Genre;
