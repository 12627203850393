import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutSection from "../components/AboutSection";

const About = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  return (
    <>
 
      <Header isAboutPage={isAboutPage}  />
      <AboutSection />
      <Footer />
    </>
  );
};

export default About;
