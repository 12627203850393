import React, { useState } from "react";
const SpecialFAQ = () => {
  // State to track which FAQ items are open
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle open/close state of FAQ item
  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <>
      <div className="faq-list">
        <h2 className="h2 mt-3">Special Features</h2>
        {FAQData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${openItem === index ? "open" : ""}`}
              onClick={() => toggleItem(index)}
            >
              {item.question}
              <i className="fas fa-chevron-down"></i>
            </div>
            <div className={`faq-answer ${openItem === index ? "open" : ""}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
const FAQData = [
    {
        question: "Can I buy books at KalpTaru?",
        answer: "Yes, you can also buy books from us. You may read a book that you like so much that you want to hold onto it forever, and not have to return it back to us. You may also want to purchase an old favourite that you no longer possess a copy of. Or you may simply want to gift a loved one a copy of your favourite book. Maybe you are just building up your own private collection. Whatever the reason, you can easily get a brand new copy delivered to your doorstep. We charge no delivery fees. We additionally give a flat 10% discount. Thus it makes sense to look to us to fulfill ALL your reading needs."
      },
      {
        question: "What is Gift a Membership?",
        answer: "Gift a Membership is a feature through which you can give your friend/relative a membership of KalpTaru as a gift."
      },
      {
        question: "How do I gift a membership?",
        answer: "Click on 'Gift a Membership'. Fill in your details and the details of the person whom you want to gift a membership. You have to make the payment for the subscription plan that you want to gift. You would be asked the date and mode of informing the person. Our representative will call you to verify and confirm the same."
      },
      {
        question: "What is Refer a Friend?",
        answer: "Refer a friend is a feature in which you can refer KalpTaru.com to your friends, by filling up a form. An e-mail would be sent to them, requesting them to join the service. For every referred friend who becomes a member of KalpTaru.com, you earn points which can be redeemed at the time of renewal of your membership plan."
      },
      {
        question: "What is Suggest a Book?",
        answer: "If there is a book that you want to read, and it is not available in our collection, then you can request for the book to be included in our library. We would try to get it as soon as possible. The price of the book would need to fall within our permissible limit."
      },
      {
        question: "How do you deliver books outside Kolkata?",
        answer: "Books outside Kolkata are delivered by our logistics partner Fedex. Once you place your request, the books are shipped and delivered within 2-3 working days."
      },
      {
        question: "How do you pickup books from locations other than Kolkata?",
        answer: "When books are delivered to locations outside Kolkata, a self addressed envelope and a shipment note is provided along with the books. When you want to return the books, you need to place your pickup & delivery request online and pack the books in the envelope provided and hand it over along with the shipment note to our logistics partner Fedex. Kindly note that you would first receive a call from Fedex to confirm if the package is ready. Only after you confirm the same would they come to collect it."
      },
      {
        question: "Will the same person who would come for pickup be delivery the next book?",
        answer: "Their pickup & delivery would be done by different people."
      },
      {
        question: "What would be the procedure in case of damage or loss of book?",
        answer: "In case of damage or loss of book, you would be required to either procure and replace the book or pay the MRP of the book."
      },
      {
        question: "How do I register for/create my account at KalpTaru.com?",
        answer: "Your account would be created by us and the login details would be mailed to you at your official email address."
      },
      {
        question: "How do I rent books?",
        answer: "To rent books, you would be required to login to your account, select the books that you would like to read from the catalogue and add them to your booklist. You would then have to arrange the books in order of priority in which you would like them to be delivered. Once that is done you can place your delivery request by clicking on the 'deliver' button present in the 'My Booklist' section."
      },
      {
        question: "What is 'My Booklist'?",
        answer: "My Booklist is the section that displays the books that you have selected for rental. This section would also show you the status of your delivery request and would provide you with options to request for delivery, pickup & reissue."
      },
      {
        question: "How do I manage 'My Booklist'?",
        answer: "You can manage your Booklist by changing the priority of the books available in your Booklist. You can also remove a book from your Booklist. To add a book to your Booklist, browse/search a book and click on 'Add to Booklist' button. To remove a book, click on the delete icon on the same row as the book on the Booklist. To rearrange books in the Booklist, just drag and drop the books and then click on 'Update Priority'."
      },
      {
        question: "What is My Account?",
        answer: "My Account is the section from where you can view and manage details regarding your account. You can edit your profile, change password, see rental history, change delivery address & view referral history."
      },
      {
        question: "What is the minimum number of books I need to maintain in My Booklist?",
        answer: "In order to start the service, you would be required to add at least 5 books in the Booklist. In order to have a high probability of a delivery, we need you to maintain at least 5 books in the Booklist, at any point of time."
      },
      {
        question: "How long can I keep the books with me?",
        answer: "Although there are no due dates specified by us, for corporate members, there are time limits specified by your company. Kindly contact your company's HR to find out the same. You can also contact us to know the details."
      },
      {
        question: "What if I damage/lose the book?",
        answer: "If you lose/damage the book, you need to either pay the MRP of the book to us or replace the copy with a new one."
      },
      {
        question: "How do I change my contact details?",
        answer: "Go to My Account> Edit Details to change your contact details."
      },
      {
        question: "How can I change my office delivery address?",
        answer: "Go to My Account>Edit Details. You would be shown the office addresses registered with us. You may choose any one of the office location."
      },
      {
        question: "How can I change my password?",
        answer: "Go to My Account> Password. You are requested to kindly change your default password after first login."
      },
      {
        question: "What if I forget my password?",
        answer: "In the Login section, there would be a 'Forgot Password' link. Just click on that and enter your registered email address/Login Id. Your password would be mailed to your email address."
      },
      {
        question: "When would the books be delivered?",
        answer: "We aim to deliver the books within 1-3 working days from the moment you request a delivery. This might however get delayed, if there is any unfavourable situation like bandh, storm, heavy rain etc."
      },
      {
        question: "What if the books are not delivered within 1-3 working days?",
        answer: "Please give us a call at 033-6#####22 , 0983####00 and we will take the case on a priority basis."
      },
      {
        question: "Where will the books be delivered?",
        answer: "The books would be delivered at the reception of your office."
      },
      {
        question: "How many books would be delivered in a single delivery?",
        answer: "This is based on your corporate plan. You can view the details in the 'My Account' section."
      },
      {
        question: "After I finish reading, how do I get my next set of books?",
        answer: "To return the books, kindly place a 'Only Pickup' or 'Pickup & delivery' request and do the following: a) Unstaple the shipment note from the return shipment envelope. b) Pack the books in the return shipment envelope provided to you at the time of delivery and seal it with a tape. c) Keep the book at the reception/ despatch section of your office."
      },
      {
        question: "What are the different options of requesting a pickup/delivery?",
        answer: "Pickup / delivery request can be made through any of the following options: (a).Through your account by going to 'My Booklist', (b).Via SMS, (c).Calling our customer care at 98####8800 or 033-65###122."
      },
      {
        question: "What if the books sent to me are not in good condition?",
        answer: "If you find that the books sent to you are not in good condition, then we request you to not accept the delivery, and register the issue with us by calling us on 033-65365122 , 09830448800 or by mailing us at customercare@kalptaru.com. We will address the issue as soon as possible, and meanwhile, we would send you the next set of books."
      }
];

export default SpecialFAQ;
